import React, { useState } from 'react'
import Layout from '../components/layout'
import { Container } from '../elements'

import { H1, Introduction } from '../elements/typography'

const ContactPage = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  function sendMessage(e) {
    e.preventDefault()
    setLoading(true)

    fetch('/.netlify/functions/send-email', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        message,
      }),
    }).then(res => {
      if (res.status >= 400) {
        console.log(res)
        setError(true)
      }
      else {
        setName('')
        setEmail('')
        setMessage('')
        setLoading(false)
        setSubmitted(true)
      }
    })
  }

  return (
    <Layout>
      <Container mt="5rem">
        <div
          css={`
            @media screen and (min-width: 550px) and (max-width: 768px) {
              padding-top: 4rem;
            }
          `}
          className="pt-8 sm:pt-16 lg:pt-24"
        >
          <H1>Wil je graag contact opnemen?</H1>
          <Introduction>
            Ik hoor graag van je! Stuur een bericht met onderstaand contact
            formulier en ik help je graag met het beantwoorden van de vragen die
            je hebt.
          </Introduction>
        </div>
        <div>
          <h3 className="font-bold text-xl tracking-tight mb-6">
            Neem contact op
          </h3>
          {isSubmitted ? (
            <p
              css={`
                background-color: var(--form-background-color);
              `}
              className="p-8"
            >
              {/* Thanks for contacting us! We'll be in touch soon! */}
              Bedankt voor je bericht. Ik neem snel contact met je op!
            </p>
          ) : (
            <form
              onSubmit={sendMessage}
              id="contactform"
              css={`
                background-color: var(--form-background-color);
              `}
              className="p-8 mb-16 max-w-3xl"
            >
              <div className="flex flex-wrap ">
                <div className="w-full md:w-1/2 md:pr-3 mb-6">
                  <label
                    css={`
                      color: var(--text-color-secondary);
                    `}
                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                    htmlFor="name"
                  >
                    Naam
                  </label>
                  <input
                    css={`
                      background-color: var(--input-background-color);
                      border-color: var(--input-border-color);
                      :disabled {
                        background-color: var(--form-background-color);
                      }
                      :focus {
                        border-color: var(--accent-color-blue);
                      }
                    `}
                    className="appearance-none block w-full border rounded py-3 px-4 leading-tight"
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Naam"
                    disabled={isLoading}
                    value={name}
                    required
                    onChange={e => setName(e.target.value)}
                  />
                  {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                </div>
                <div className="w-full md:w-1/2  mb-6">
                  <label
                    css={`
                      color: var(--text-color-secondary);
                    `}
                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                    htmlFor="email"
                  >
                    Email adres
                  </label>
                  <input
                    css={`
                      background-color: var(--input-background-color);
                      border-color: var(--input-border-color);
                      :disabled {
                        background-color: var(--form-background-color);
                      }
                      // &:invalid:not(:focus):not(:placeholder-shown) {
                      //   background-color: yellow;
                      // }
                      :focus {
                        border-color: var(--accent-color-blue);
                      }
                    `}
                    className="appearance-none block w-full border rounded py-3 px-4 leading-tight"
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Email adres"
                    disabled={isLoading}
                    value={email}
                    required
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className="w-full  mb-6">
                  <label
                    css={`
                      color: var(--text-color-secondary);
                    `}
                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                    htmlFor="message"
                  >
                    Bericht
                  </label>
                  <textarea
                    css={`
                      background-color: var(--input-background-color);
                      border-color: var(--input-border-color);
                      :disabled {
                        background-color: var(--form-background-color);
                      }
                      :focus {
                        border-color: var(--accent-color-blue);
                      }
                    `}
                    className="appearance-none block h-48 w-full border shadow-none rounded py-3 px-4 leading-tight"
                    id="message"
                    type="text"
                    name="message"
                    form="contactform"
                    placeholder="Typ hier je bericht"
                    disabled={isLoading}
                    value={message}
                    required
                    onChange={e => setMessage(e.target.value)}
                  />
                  {/* <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
                </div>
                <button
                  type="submit"
                  className="w-full sm:w-auto  font-semibold text-lg py-2 px-8 rounded border leading-tight"
                  css={`
                    transition: 300ms all cubic-bezier(0.4, 0, 0.6, 1);
                    background-color: var(--accent-color-blue);
                    border-color: var(--accent-color-blue);
                    color: var(--color-white);
                  `}
                  disabled={isLoading}
                >
                  {isLoading ? 'Sending...' : 'Verstuur'}
                </button>
              </div>
                  {error && <p><strong>Error</strong></p>}
            </form>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default ContactPage
