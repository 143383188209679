import styled from 'styled-components'

export const H1 = styled.h1`
  font-weight: 700;
  font-size: 2.25rem;
  letter-spacing: -0.025em;
  margin-bottom: 1.5rem;
  line-height: 1;
  color: var(--color-primary);

  @media (min-width: 768px) {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
`
export const H2 = styled.h2`
  font-weight: 700;
  font-size: 1.875rem;
  letter-spacing: -0.025em;
  line-height: 1;
  margin-bottom: 1rem;
  color: var(--color-primary);

  @media (min-width: 768px) {
    font-size: 2.25rem;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1024px) {
    font-size: 3rem;
  }
`

export const H3 = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 1rem;
  color: var(--color-primary);

  @media (min-width: 768px) {
    font-size: 1.875rem;
    margin-bottom: 1.5rem;
  }
`

export const Introduction = styled.p`
  font-weight: 400;
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
  line-height: 1.375;
  color: var(--color-secondary);

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }

  strong {
    color: var(--color-primary);
  }
`

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5;
  color: var(--color-secondary);

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }

  @media (min-width: 1280px) {
    font-size: 1.25rem;
  }
`
